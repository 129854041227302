<template>
  <div style="padding: 20px">
    <div style="text-align: center">{{name}}</div>
    <div v-html="content"></div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        name:'',
        content:''
      }
    },
    created () {
      document.title = this.$route.query.title+ '会员服务协议'
      this.name  = sessionStorage.getItem("name")
      this.content = sessionStorage.getItem("content")
    },
  }
</script>